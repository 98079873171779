import { validationMixin } from 'vuelidate'
import { required, url } from 'vuelidate/lib/validators'
import SystemService from '@/services/SystemService.js'
import IntegrationService from '@/services/IntegrationService.js'

export const SystemMixin = {
  mixins: [validationMixin],
  validations: {
    systemObj: {
      name: { required },
      url: { required, url },
      operation_url: { url },
      system_type: { required },
      system_codes: {}
    }
  },
  watch: {
    model(val, prev) {
      if (val.length === prev.length) return

      this.model = val.map(v => {
        if (typeof v === 'string') {
          this.systemObj.smart_codes.push(v)

          this.nonce++
        }

        return v
      })
    }
  },
  data() {
    return {
      dialog: false,
      model: [],
      systemToUpdate: false,
      nonce: 1,
      x: 0,
      y: 0,
      systemObj: this.createFreshSystemObject()
    }
  },
  methods: {
    createSystem() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (!this.systemToUpdate) {
          SystemService.addSystem(this.systemObj)
            .then(() => {
              this.createFreshSystemObject()
              this.getDataFromAPI(0).then(data => {
                // console.log(data.items)
                this.items = data.items
                this.total = data.total
              })
              const notification = {
                type: 'success',
                message: `System with name ${this.systemObj.name} created.`
              }
              this.$store.dispatch('notification/add', notification, {
                root: true
              })
            })
            .catch(error => {
              this.$store.dispatch('setLoader', False)
              console.log(error.response)
            })
        } else {
          SystemService.updateSystem(this.systemObj._id, {
            name: this.systemObj.name,
            url: this.systemObj.url,
            system_type: this.systemObj.system_type,
            smart_codes: this.systemObj.smart_codes,
            operation_url: this.systemObj.operation_url
          })
            .then(() => {
              this.systemToUpdate = false
              this.createFreshSystemObject()
              this.getDataFromAPI(0).then(data => {
                // console.log(data.items)
                this.items = data.items
                this.total = data.total
              })
              const notification = {
                type: 'success',
                message: `System with name ${this.systemObj.name} updated.`
              }
              this.$store.dispatch('notification/add', notification, {
                root: true
              })
            })
            .catch(error => {
              this.$store.dispatch('setLoader', false)
              console.log(error.response)
            })
        }
        this.systemObj = this.createFreshSystemObject()
        this.dialog = false
      }
    },
    getSystem(systemID) {
      SystemService.getSystem(systemID)
        .then(response => {
          this.systemToUpdate = true
          this.systemObj = response.data
          // this.model = this.systemObj.smart_codes
          this.dialog = true
        })
        .catch(error => {
          console.log(error.response)
          this.$store.dispatch('setLoader', false)
        })
    },
    filter(item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => (val != null ? val : '')

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      )
    },
    createFreshSystemObject() {
      return {
        name: '',
        url: '',
        system_type: this.$route.params.system_type,
        smart_codes: []
      }
    },
    runSync(systemId, smartCodes) {
      IntegrationService.runSync(systemId, smartCodes)
        .then(() => {
          this.getDataFromAPI(0).then(data => {
            // console.log(data.items)
            this.items = data.items
            this.total = data.total
          })
          const notification = {
            type: 'success',
            message: `Synchronisation for ${smartCodes} finished`
          }
          this.$store.dispatch('notification/add', notification, { root: true })
        })
        .catch(error => {
          console.log(error.response)
          this.$store.dispatch('setLoader', false)
        })
    }
  },
  computed: {
    system() {
      return this.$store.state['systems']['system']
    },
    breadcrumbs() {
      return this.$store.state.breadcrumbs.breadcrumbs
    },
    isAdmin() {
      return this.$store.getters['auth/isAdmin']
    },
    nameErrors() {
      const errors = []
      if (!this.$v.systemObj.name.$dirty) return errors
      !this.$v.systemObj.name.required && errors.push('Name is required.')
      return errors
    },
    urlErrors() {
      const errors = []
      if (!this.$v.systemObj.url.$dirty) return errors
      !this.$v.systemObj.url.required && errors.push('URl is required.')
      !this.$v.systemObj.url.url && errors.push('invalid url')
      return errors
    },
    // systemTypeErrors() {
    //   const errors = []
    //   if (!this.$v.systemObj.system_type.$dirty) return errors
    //   !this.$v.systemObj.system_type.required &&
    //     errors.push('System Type is required.')
    //   return errors
    // },
    systemCodesErrors() {
      const errors = []
      if (!this.$v.systemObj.system_codes.$dirty) return errors
      console.log(this.systemObj.smart_codes.length)
      if (this.systemObj.smart_codes.length === 0) {
        errors.push('System codes is required')
      }
      return errors
    },
    systemType() {
      return this.$route.params.system_type || this.system.systemType
    }
  }
}
